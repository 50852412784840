import React from 'react';

import Header from '../components/header';
import MacbookSection from '../components/macbookSection';
import Footer from '../components/footer';
import ActionSection from '../components/actionSection';
import MobileFooter from '../components/mobileFooter';
import SEO from '../components/seo/SEO';
import PodcastPhoneSlider from '../components/podcastPhoneSlider';
import PodcastHero from '../components/podcastHero';

export default () => (
  <div className='application'>
    <SEO />
    <Header />
    <a
      href='https://www.producthunt.com/posts/podcasts-by-raterfox?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-podcasts-by-raterfox'
      target='_blank'>
      <img
        src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=159214&theme=light'
        alt='Podcasts by RaterFox - Social recommendation app | Product Hunt Embed'
        style={{
          width: 250,
          height: 54,
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
          marginTop: 40
        }}
        width='250px'
        height='54px'
      />
    </a>
    <PodcastHero />
    <PodcastPhoneSlider isMobile={true} />
    <MacbookSection />
    <PodcastPhoneSlider isMobile={false} />
    <ActionSection />
    <Footer />
    <MobileFooter />
  </div>
);
