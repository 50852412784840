import React, { Component } from 'react';
import Slider from 'react-slick';

import styles from './podcastPhoneSlider.module.css';

import screenshot1 from '../assets/images/screenshots/podcast-discover.png';
import screenshot2 from '../assets/images/screenshots/podcast-review.png';
import screenshot3 from '../assets/images/screenshots/podcast-recommend.png';
import screenshot4 from '../assets/images/screenshots/podcast-save.png';
import screenshot5 from '../assets/images/screenshots/podcast-activity.png';

const screenshots = [
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot5
];

export default class PodcastPhoneSlider extends Component {
  renderScreenShot(screenshot) {
    return (
      <div style={{ maxWidth: 150 }}>
        <img src={screenshot} className={styles.carousalImage} />
      </div>
    );
  }

  render() {
    var settings = {
      autoplay: true,
      dots: false,
      speed: 800,
      autoplaySpeed: 2500,
      infinite: true,
      slidesToShow: 5,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1320,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className={this.props.isMobile ? styles.isMobile : styles.isDesktop}>
        <div className={styles.headerWrapper}>
          <h2>Recommend podcasts to friends</h2>
          <p style={{ maxWidth: 560, marginLeft: 'auto', marginRight: 'auto' }}>
            Connect with friends and get real recommendations from the people
            who know you best.
          </p>
        </div>

        <div
          style={{ position: 'relative', marginBottom: 100, width: '100%' }}
          className={this.props.isMobile ? styles.isMobile : styles.isDesktop}>
          <div className={styles.carousalPhoneContainer}>
            <div className={styles.phone} />
          </div>
          <Slider {...settings}>
            {screenshots.map(screenshot => this.renderScreenShot(screenshot))}
          </Slider>
        </div>
      </div>
    );
  }
}
